import {
  Hero,
  Features,
  Hero2,
  PopularProducts,
  Touch,
  Banner,
  Story,
} from "@/components/Pages/HomePage";
import Head from "next/head";

export default function Home() {
  return (
    <>
      <Head>
        <title>
          Furniture & Homewares Online at Beautiful Prices | Priceworth
          Furniture
        </title>
        <meta
          name="description"
          content="Discover beautiful furniture and homewares at affordable prices. Shop now at Priceworth Furniture."
        />
        <meta
          property="og:image"
          content="https://www.priceworth.com.au/category.webp"
        />
        <meta property="og:site_name" content="Priceworth Furniture" />
        <meta
          property="og:title"
          content="Furniture & Homewares Online at Beautiful Prices | Priceworth Furniture"
        />
        <meta
          property="og:description"
          content="Discover beautiful furniture and homewares at affordable prices. Shop now at Priceworth Furniture."
        />
        <meta property="og:url" content="https://www.priceworth.com.au" />
        <meta
          property="twitter:image"
          content="https://www.priceworth.com.au/category.webp"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:title"
          content="Furniture & Homewares Online at Beautiful Prices | Priceworth Furniture"
        />
        <meta
          property="twitter:description"
          content="Discover beautiful furniture and homewares at affordable prices. Shop now at Priceworth Furniture."
        />
      </Head>
      <h1 className="sr-only">
        Furniture & Homewares Online at Beautiful Prices
      </h1>
      <Hero />
      <Features />
      <Hero2 />
      <PopularProducts />
      <Touch />
      <Banner />
      <Story />
    </>
  );
}
