interface ServiceProps {
  icon: React.ReactElement;
  title: string;
  description: string;
  link: string;
}

const Service: React.FC<ServiceProps> = (props) => {
  const { icon, title, description, link } = props;
  return (
    <div className="flex-1 flex flex-col items-center ">
      <div className="text-6xl">{icon}</div>
      <h4 className="text-lg font-semibold">{title}</h4>
      <p className="text-center">{description}</p>
    </div>
  );
};

export default Service;
