import { MdOutlineArrowForwardIos } from "react-icons/md";
import Link from "next/link";
import { gsap } from "gsap";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { Button } from "@nextui-org/react";

const Left = () => {
  const containerRef = useRef(null);

  useGSAP(
    () => {
      const items = gsap.utils.toArray(".item");
      gsap.fromTo(
        items,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          stagger: 0.5,
          ease: "power1.out",
          duration: 1,
          delay: 0.2,
        },
      );
    },
    { scope: containerRef },
  );

  return (
    <div
      ref={containerRef}
      className="bg-white bg-opacity-60 py-4 px-2 lg:py-16 lg:px-8 transition-all duration-500"
    >
      <p className="item opacity-0 text-sm md:text-base lg:text-lg font-bold transition-all duration-500">
        Quick parcel delivery, <span className="text-icon">from $49</span>
      </p>
      <h2 className="item opacity-0 text-base sm:text-2xl md:text-4xl font-bold mt-2 lg:text-6xl transition-all duration-500">
        Get used to better <br /> living space
      </h2>
      <p className="item opacity-0 mt-3 sm:mt-6 text-sm lg:text-base transition-all duration-500">
        Shop quality, stylish and smart furniture here.
      </p>
      <p className="item opacity-0 text-sm lg:text-base transition-all duration-500">
        Check out fresh design ideas.
      </p>
      <Link href="/category">
        <Button className="item opacity-0 bg-gray-950 text-white font-semibold text-sm lg:text-base rounded-md px-2 sm:px-4 pt-6 pb-7 flex items-center gap-4 mt-4 sm:mt-8 hover:bg-icon transition-all duration-500">
          Start Shopping <MdOutlineArrowForwardIos />
        </Button>
      </Link>
    </div>
  );
};

export default Left;
