import Image from "next/image";
import Left from "./components/Left";
import Right from "./components/Right";
import PlayButton from "@/components/Shares/PlayButton";

const Hero = () => (
  <div className="relative container mx-auto my-4 lg:my-16 transition-all duration-500">
    <div className="absolute top-0 z-10 lg:top-3 xl:top-16 left-0 w-1/2 transition-all duration-500">
      <Left />
    </div>
    <div className="relative w-full h-auto ml-auto lg:w-3/4">
      <Image
        src="/hero.webp"
        alt="hero"
        width={1300}
        height={900}
        className="w-full h-auto transition-all duration-500"
        loading="eager"
      />
      <div className="absolute inset-0 md:flex items-center justify-center hidden">
        <PlayButton />
      </div>
    </div>
    <div className="absolute right-0 bottom-0 lg:right-10 lg:bottom-10 transition-all duration-500 ">
      <Right />
    </div>
  </div>
);

export default Hero;
